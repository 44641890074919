import React from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom";

function Gallery(props) {
  let img = props.gallery.length > 0 ?
    props.gallery.map(item => {
      return (
        <div className="img" key={item.id}>
          <img src={`${process.env.REACT_APP_API_URL}${item.url}`} alt={`gallery-image-${item.id}`} />
        </div>
      )
    })
    :
    <div className='title'>
      <p>Will be showed soon</p>
    </div>

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div id='gallery' className="gallery-part">
      <div className="title text-center">
        <p>photos of event</p>
        <h1>Event Gallery</h1>
      </div>
      <Slider {...settings}>
        {img}
      </Slider>
      <div className="button">
        <Link>
          <button>see more</button>
        </Link>
      </div>
    </div>
  )
}

export default Gallery
