/* eslint-disable */
import React from 'react';
function CountDown(props) {

  var countDownDate = new Date(props.count).getTime();

  var x = setInterval(function () {
    var now = new Date().getTime();

    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    var day = document.getElementById("day");
    var hour = document.getElementById("hour");
    var min = document.getElementById("min");
    var sec = document.getElementById("sec");
    if (day && hour && min && sec) {
      day.innerHTML = days;
      hour.innerHTML = hours;
      min.innerHTML = minutes;
      sec.innerHTML = seconds;
    }

    if (distance < 0) {
      clearInterval(x);
      document
        .getElementById("countdown")
        .innerHTML = "<h1>EXPIRED</h1>";
    }
  }, 100);

  return (
    <div className="counterdown" id='countdown'>
      <div className="day">
        <p id="day"></p>
        <span>Days</span>
      </div>
      <div className="hour">
        <p id="hour"></p>
        <span>Hours</span>
      </div>
      <div className="min">
        <p id="min"></p>
        <span>Minutes</span>
      </div>
      <div className="sec">
        <p id="sec"></p>
        <span>Seconds</span>
      </div>

    </div>
  )
}

export default CountDown;