import React from 'react'
import Banner from '../components/banner/Banner';

export default function Error(props) {
    return (
        <section className="error-page">
            <Banner
                event={props.event}
            />
        </section>
    )
}
