import LocationIcon from '../../assets/images/locationimg.png';
import PhoneIcon from '../../assets/images/address.png'

function FestivalLocation(props) {

  return (

    <div className="loaction">
      <div className="address">
        <div className="add">
          <img src={LocationIcon} alt={props.location} />
          <p>{props.location}</p>
        </div>
        <div className="add">
          <img className="addimg" src={PhoneIcon} alt={props.phone} />
          {
            props.phones.map(p => <p key={p.id} className="no">{p.phone}</p>)
          }
        </div>
      </div>
    </div>
  )
}

export default FestivalLocation
