function FestivalText(props) {
  return (
    <div>
      <h1 className="text-title">{props.slogan}</h1>
      <p className="para1">{props.description}</p>
    </div>
  )
}



export default FestivalText;

