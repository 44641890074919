import React from 'react';

function Video(props) {
  return (

    <div className="icon">
      <span className="vdo">Watch Full Video</span>
      <a
        className="venobox"
        data-autoplay="true"
        data-vbtype="video"
        href={props.video}>
        <i className="fa fa-play" aria-hidden="true"></i>
      </a>

    </div>
  )
}

export default Video;