import React from 'react'
import SpeakerBanner from '../../assets/images/shedulebanner.png'

function Schedule(props) {

  const speakers = props.speakers.length > 0 ?
    props.speakers
      .map(item => {
        const startTime = new Date(item.start_time).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
        const endTime = new Date(item.end_time).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
        return (
          <div key={item.id} className="col-lg-6 col-md-6">
            <div className="speaker">
              <div className="img">
                <img style={{ borderRadius: "50%", height: 160, border: "3px white solid" }} src={`${process.env.REACT_APP_API_URL}${item.image.url}`} alt={item.designation} />
              </div>
              <div className="details">
                <div className="date">
                  <p>{startTime} - {endTime}</p>
                </div>
                <h1 className="name">{item.fullname}</h1>
                <p className="desination">{item.position}</p>
                {
                  (item.twitter || item.linkedin) &&
                  <div className="sheduleicon">
                    <span>Follow</span>
                    {item.twitter && <a href="#"> <i className="fa fa-twitter"></i></a>}
                    {item.linkedin && <a href="#"><i className="fa fa-linkedin"></i></a>}
                  </div>
                }
              </div>
            </div>
          </div>

        )
      })
    :
    <div className='title text-center'>
      <p>Will be announced soon</p>
    </div>

  return (
    <div id='speakers' style={{ backgroundImage: `url(${SpeakerBanner})`, backgroundSize: "cover" }} className="shedule-part">
      <div className="overlay">
        <div className="title">
          <p>Time Table & Speaking People</p>
          <h1>Schedule & Speakers</h1>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            {speakers}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Schedule
