import React from 'react';
import Spinner from '../../assets/images/spinner.gif'

function Loader() {

  return (
    <div>
      <img style={{ position: 'absolute', top: "50%", left: "50%", transform: "translate(-50%,-50%)" }} src={Spinner} />
    </div>
  )
}

export default Loader;