import React from 'react'
import { Link } from "react-router-dom";

function UpcomingButton(props) {
    return (
        <Link to="/pricing-plan">
            <button>Book Your Seat</button>
        </Link>
    )
}

export default UpcomingButton

