import React from 'react'
import Banner from '../components/banner/Banner';
import Festival from '../components/festival/Festival';
import Upcoming from '../components/upcoming/upcoming';
import Navbar from '../components/navbar/Navbar';
import Gallery from '../components/gallery/Gallery';
import Sponsor from '../components/sponsor/sponsor'
import Footer from '../components/footer/footer'
import { navitem, image, galleryImg, footerbg } from '../components/variables/variable'
import Schedule from '../components/shedule/Schedule';

function Home(props) {

  return (
    <div>
      <Navbar name={navitem} logo={image} action={props.event.action} />
      <Banner
        event={props.event}
      />
      <Festival event={props.event} />
      <Schedule speakers={props.event.speakers} />
      <Upcoming workshops={props.event.workshops} />
      <Gallery gallery={props.event.gallery} />
      {/* <Event feedback={feedback} /> */}
      {/* <Pricing plan={pricingPlane} selectplan={selectplan} /> */}
      <Sponsor sponsors={props.event.sponsors} />
      <Footer bg={footerbg} />
    </div>
  )
}

export default Home;