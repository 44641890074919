import React from 'react'
import Slider from "react-slick";
import FestivalText from './FestivalText';
import FestivalLocation from './FestivalLocation';

function Festival(props) {
  const eventDate = new Date(props.event.date)
  const festiveSlider = {
    arrows: false,
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }, {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  let Img = props.event.slider.map(img => {
    return (<img key={img.id} src={`${process.env.REACT_APP_API_URL}${img.url}`} />)
  })

  let year = Array.from(`${eventDate.getFullYear()}`)

  return (
    <div id='overview' className="festive-part">
      <div className="container p-0">
        <div className="row mx-0">
          <div className="col-lg-5">
            <Slider {...festiveSlider}>
              {Img}
            </Slider>
          </div>

          <div className="col-lg-7 p-0">
            <div className="festive-left">
              <div className="text-img">
                <h1>{year[0]}{year[1]}</h1>
                <h1>{year[2]}{year[3]}</h1>
                <p>{eventDate.getDate()} {eventDate.toLocaleString('en-us', { month: 'long' })}</p>
              </div>
              <div className="text">
                <FestivalText slogan={props.event.slogan} description={props.event.description} />
                <FestivalLocation location={props.event.location} phones={props.event.phones} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Festival
