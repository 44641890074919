import React from 'react'
import LocationIcon from '../../assets/images/locationimg.png';
import PhoneIcon from '../../assets/images/address.png'

function UpcomingLocation(props) {

    return (
        <div className="location">
            {
                props.address &&
                <div className="address">
                    <div className="img immg">
                        <img src={LocationIcon} />
                    </div>
                    <div className="details">
                        <p>{props.address}</p>
                    </div>
                </div>
            }
            {
                props.number &&
                <div className="address shape">
                    <div className="img immg">
                        <img src={PhoneIcon} alt={props.phoneimg} />
                    </div>
                    <div className="details number">
                        <p>{props.number}</p>
                    </div>
                </div>
            }
        </div>
    )
}



export default UpcomingLocation;

