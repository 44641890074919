import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import NavButton from "./registerbutton/NavButton";

function Navbar(props) {
  let navItem = props.name.map((item) => {
    return (
      <li key={item.id} className="nav-item">
        <Link smooth to={item.link}>{item.name}</Link>
      </li>
    )
  })

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={props.logo} alt={props.logo} />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fa fa-bars" aria-hidden="true"></i>

        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {navItem}
            <Link to="/call-for-sponsors">
              <NavButton action={props.action} />
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;