import React from 'react';
import BannerText from './BannerText';
import CountDown from './bannerCountDown';
import Video from './Video';
import defaultBG from '../../assets/images/default-bg.webp'

function Banner(props) {

  return (
    <section style={{ backgroundImage: props.event?.hero.url ? `url(${process.env.REACT_APP_API_URL}${props.event.hero.url})` : `url(${defaultBG})`, backgroundSize: "cover" }} className="banner-part">
      <div className="overlay">
        <div className="container">
          <BannerText heading={props.event?.name} />
          <div className="row">
            <div className="col-lg-9">
              <CountDown count={props.event?.date} />
            </div>

            <div className="col-lg-3 p-0">
              <Video video={props.event?.video} />
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Banner;