import React from 'react';
import UpcomingLocation from './UpcomingLocation';
import UpcomingButton from './UpcomingButton';
import { Link } from "react-router-dom";
import ScrollArea from 'react-scrollbar';

function upcoming(props) {
  let workshops = props.workshops.length > 0 ?
    props.workshops.map(item => {
      return (
        <div key={item.id} className="col-lg-12">
          <div className="event">
            <div className="img">
              <img width={140} src={`${process.env.REACT_APP_API_URL}${item.image.url}`} alt={item.img} />
            </div>
            <div className="details">
              <h2>{item.title}</h2>
              <UpcomingLocation
                address={item.location}
                number={item.number} />
            </div>
            <div className="button">
              <Link to="/call-for-sponsors" target="_blank"><UpcomingButton /></Link>
            </div>
          </div>
        </div>
      )
    })
    :
    <div className='title text-center'>
      <p>Will be announced soon</p>
    </div>
  return (
    <div id='workshops' className="upcoming-part">
      <div className="title text-center">
        <p>training</p>
        <h1>UPCOMING WORKSHOPS</h1>
      </div>
      <div className="container area">
        <div className="row">
          <ScrollArea
            speed={0.5}
            className="area"
            contentClassName="content"
            horizontal={false}
          >
            {workshops}
          </ScrollArea>

        </div>
      </div>
    </div>
  )
}

export default upcoming;
