import React from 'react';
import { Link } from "react-router-dom";
import errorImage from '../../assets/images/error.png'

function BannerText(props) {
  return (
    <div>
      <div className="error">
        <p className="errortitle">
          Page not found
        </p>
        <img src={errorImage} alt={props.err} />
        <p className="gohome">Go
          <span>
            <a href="/">
              home
            </a>
          </span>
        </p>
      </div>
      <div className="homebanner">
        <p>Don't Miss it</p>
        <h1>{props.heading}</h1>
      </div>
      <div className="ericon">
        <Link to="https://www.facebook.com/GDGSousse/" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
        <Link to="https://www.linkedin.com/company/gdg-sousse" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
        <Link to="https://www.instagram.com/gdgsousse/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
      </div>
      <div className="form">
        <form>
          <input type="text" placeholder="Your E-mail Here" />
          <button type="submit">Subscribe</button>
        </form>
      </div>
      <p className="sub">Subscribe to our newsletter & get every update notifications</p>
    </div>
  )
}

export default BannerText;