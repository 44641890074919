import React from 'react';
import { useNavigate } from 'react-router-dom';

function NavButton(props) {
    const navigate = useNavigate()

    return (
        <button>{props.action.toUpperCase()}</button>
    )
}

export default NavButton;