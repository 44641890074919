import React from 'react'

function sponsor(props) {
  let img = props.sponsors.length > 0 ?
    props.sponsors.map(function (item) {
      return (
        <img key={item.id} src={`${process.env.REACT_APP_API_URL}${item.image.url}`} alt={item.name} />
      )
    })
    :
    <div className='title'>
      <p>Will be announced soon</p>
    </div>

  return (
    <div id='sponsors' className="sponsor-part">
      <div className="title text-center">
        <p>supporters</p>
        <h1>Our Sponsors</h1>
      </div>
      <div className="container">
        <div className="img">
          {img}
        </div>
      </div>
    </div>
  )
}

export default sponsor
