import logo from '../../assets/images/logo.png';
import Gallery1 from '../../assets/images/gallery1.png'
import Gallery2 from '../../assets/images/gallery2.png'
import Gallery3 from '../../assets/images/gallery3.png'
import Gallery4 from '../../assets/images/gallery4.png'
import Gallery5 from '../../assets/images/gallery5.png'
import Gallery6 from '../../assets/images/gallery6.png'
import Gallery7 from '../../assets/images/gallery8.png'
import Gallery8 from '../../assets/images/gallery9.png'
import Gallery9 from '../../assets/images/gallery10.png'
import Gallery10 from '../../assets/images/gallery11.png'
import Gallery11 from '../../assets/images/gallery12.png'
import Gallery12 from '../../assets/images/gallery13.png'
import Feedback1 from '../../assets/images/feedback1.png'
import Feedback2 from '../../assets/images/feedback2.png'
import Sponsor1 from '../../assets/images/sponsor1.png'
import Sponsor2 from '../../assets/images/sponsor2.png'
import Sponsor3 from '../../assets/images/sponsor3.png'
import Sponsor4 from '../../assets/images/sponsor4.png'
import Sponsor5 from '../../assets/images/sponsor5.png'
import Sponsor6 from '../../assets/images/sponsor6.png'
import Sponsor7 from '../../assets/images/sponsor7.png'
import Sponsor8 from '../../assets/images/sponsor8.png'
import Sponsor9 from '../../assets/images/sponsor9.png'
import Sponsorname from '../../assets/images/sponsorname1.png'
import Sponsorname2 from '../../assets/images/sponsorname2.png'
import Sponsorname3 from '../../assets/images/sponsorname3.png'
import Sponsorname4 from '../../assets/images/sponsorname4.png'
import Aboutbanner from '../../assets/images/aboutbanner.png'
import Footerbg from '../../assets/images/footerbg.png'
// nav Item variable start
const navitem = [
  {
    id: 1,
    name: "Overview",
    link: "/#overview"
  },
  {
    id: 2,
    name: "Speakers",
    link: "/#speakers"
  },
  {
    id: 3,
    name: "Workshops",
    link: "/#workshops"
  },
  {
    id: 4,
    name: "Gallery",
    link: "/#gallery"
  },
  {
    id: 5,
    name: "Sponsors",
    link: "/#sponsors"
  }
]
// nav Item variable end 
// logo variable start
const image = logo

let galleryImg = [
  {
    id: 1,
    img: Gallery1
  },
  {
    id: 2,
    img: Gallery2
  },
  {
    id: 3,
    img: Gallery3
  },
  {
    id: 4,
    img: Gallery4
  },
  {
    id: 5,
    img: Gallery5
  },
  {
    id: 6,
    img: Gallery6
  },
  {
    id: 7,
    img: Gallery7
  },
  {
    id: 8,
    img: Gallery8
  },
  {
    id: 9,
    img: Gallery9
  },
  {
    id: 10,
    img: Gallery10
  },
  {
    id: 11,
    img: Gallery11
  },
  {
    id: 12,
    img: Gallery12
  },

]
// gallery variable end

// event variable start

let feedback = [
  {
    id: 1,
    details: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
    img: Feedback1,
    icon: "fa fa-star",
    name: "Jonathon Reverse",
    designation: "Event Sponsor",

  },
  {
    id: 2,
    details: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
    img: Feedback2,
    icon: "fa fa-star",
    name: "Jerinno Lopez",
    designation: "Event Sponsor",

  },
  {
    id: 3,
    details: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
    img: Feedback1,
    icon: "fa fa-star",
    name: "Jonathon Reverse",
    designation: "Event Sponsor",
  },
]
// event variable end


// pricing part variable start

let pricingPlane = [
  {
    id: 1,
    name: "Regular Plan",
    price: "$29.99",
    facility: ["A+ Class Seat", "Free WiFi", "Unlimited Coffee", "Lunch", "Workshop", "Stationary", "X", "X"]
  },
  {
    id: 2,
    name: "Premium Plan",
    price: "$59.99",
    facility: ["A+ Class Seat", "Free WiFi", "Unlimited Coffee", "Lunch", "Workshop", "Stationary", "X", "X"]
  },
  {
    id: 3,
    name: "Platinum Plan",
    price: "$99.99",
    facility: ["A+ Class Seat", "Free WiFi", "Unlimited Coffee", "Lunch", "Workshop", "Stationary", "X", "X"]
  }
]

let selectplan = [
  {
    id: 1,
    plan: "Plan1"
  },
  {
    id: 2,
    plan: "Plan2"
  },
  {
    id: 3,
    plan: "Plan3"
  },
  {
    id: 4,
    plan: "Plan4"
  },
  {
    id: 5,
    plan: "Plan5"
  },
  {
    id: 6,
    plan: "Plan6"
  },
]

// pricing part variable end

let sponsorimg = [
  {
    id: 1,
    img: Sponsor1
  },
  {
    id: 2,
    img: Sponsor2
  },
  {
    id: 3,
    img: Sponsor3
  },
  {
    id: 4,
    img: Sponsor4
  },
  {
    id: 5,
    img: Sponsor5
  },
  {
    id: 6,
    img: Sponsor6
  },
  {
    id: 7,
    img: Sponsor7
  },
  {
    id: 8,
    img: Sponsor8
  },
  {
    id: 9,
    img: Sponsor9
  },
]
// sponsor part variable end


// footer part variable start

let footerbg = {
  backgroundImage: `url(${Footerbg})`,
  backgroundSize: "cover"
}

// footer part variable end


// about page variable start
let aboutbgimg = {
  backgroundImage: `url(${Aboutbanner})`,
  backgroundSize: "cover"
}

// about page variable end


// sponsor page variable start

let sponsorname = [
  {
    id: 1,
    level: "most precious",
    title: "Title Sponsor",
    img: Sponsorname,
  },
  {
    id: 2,
    level: "2nd precious",
    title: "Signature Sponsor",
    img: Sponsorname2,
  },
  {
    id: 3,
    level: "micro supporter",
    title: "Golden Sponsorr",
    img: Sponsorname3,
  },
  {
    id: 4,
    level: "micro supporter",
    title: "Silver Sponsor",
    img: Sponsorname4,
  },
]

// sponsor page variable end


export { navitem, image, galleryImg, feedback, pricingPlane, selectplan, sponsorimg, footerbg, aboutbgimg, sponsorname }