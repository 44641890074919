import React, { useEffect, useState } from 'react';

import '../src/assets/css/slick.css';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/navbar.css';
import '../src/assets/css/navbutton.css';
import '../src/assets/css/banner.css';
import '../src/assets/css/festiveslider.css';
import '../src/assets/css/shedule.css';
import '../src/assets/css/upcoming.css';
import '../src/assets/css/gallery.css';
import '../src/assets/css/event.css';
import '../src/assets/css/pricing.css';
import '../src/assets/css/sponsor.css';
import '../src/assets/css/about-page.css';
import '../src/assets/css/pricingpahe.css';
import '../src/assets/css/sponsorpage.css';
import '../src/assets/css/comingsoon.css';
import '../src/assets/css/error-page.css';
import '../src/assets/css/footer.css';
import '../src/assets/css/responsive.css';
import './App.css';

import Home from './pages/home';
import Pricing from './pages/pricing_plane';
import Error from './pages/error';

import Loader from './components/loader';

import { Route, Routes } from "react-router-dom";
import axios from 'axios';
import CallForSponsors from './pages/CallForSponsors';

function App() {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/events/${process.env.REACT_APP_CURRENT_EVENT_ID}?populate[hero][fields]=url&populate[slider][fields]=url&populate[phones][fields]=phone&populate[speakers][populate][image][fields]=url&populate[workshops][populate][image][fields]=url&populate[sponsors][populate][image][fields]=url&populate[gallery][fields]=url`, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` }
      })
        .then(response => setEvent(response.data.data))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }

    fetchEvent()
  }, [])

  return (
    <Routes>
      <Route exact path="/" element={loading ? <Loader /> : <Home event={event} />}></Route>
      {/* <Route exact path="/gallery" element={<Gallery />}></Route> */}
      <Route exact path="/call-for-sponsors" element={loading ? <Loader /> : <CallForSponsors event={event} />}></Route>
      {/* <Route exact path="/sponsor" element={<Sponsor />}></Route> */}
      <Route path="*" element={<Error />}></Route>
    </Routes>
  )
}

export default App;