import { useState } from "react";
import Spinner from '../../../assets/images/spinner.gif'
import axios from "axios";

function SponsorForm(props) {
  const [formElements, setFormElement] = useState({})
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const submitSponsorsForm = async () => {
    setLoading(true)
    await axios.post(`${process.env.REACT_APP_API_URL}/api/sponsors`, { data: { ...formElements, event: process.env.REACT_APP_CURRENT_EVENT_ID } }, {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` }
    })
      .then(response => setSuccess(true))
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }
  return (
    success ?
      <h5 className="text-center mt-5">Thanks {formElements.fullname} !<br />Your sponsoring interest has been submitted succesfully.<br />We'll get in touch with you ASAP.</h5>
      :
      <form onSubmit={(e) => { e.preventDefault(); submitSponsorsForm() }}>
        <label className="heading">Submit your interest & we'll contact you</label>
        <input className="firstname ml" onChange={(e) => setFormElement({ ...formElements, fullname: e.target.value })} name="fullname" type="text" placeholder="Your fullname" />
        <input className="firstname" onChange={(e) => setFormElement({ ...formElements, company: e.target.value })} name="company" type="text" placeholder="your company name" />
        <input className="email ml" onChange={(e) => setFormElement({ ...formElements, email: e.target.value })} name="email" type="email" placeholder="your e-mail address " />
        <input className="email" onChange={(e) => setFormElement({ ...formElements, phone: e.target.value })} name="phone" type="phone" placeholder="your phone number" />
        <button className="submit" type="submit">
          {
            loading ? <img width={50} src={Spinner} alt="Loading..." />
              :
              "Send"
          }
        </button>
      </form>
  )
}

export default SponsorForm
