import React from 'react'
import { Link } from "react-router-dom";
function footer(props) {

  return (
    <section style={props.bg} className="footer-part">
      <div className="title">
        <p>get updated</p>
        <h2>Subscribe for Newsletter</h2>
      </div>
      <form>
        <input type="text" placeholder="Your e-mail Here" />
        <button type="submit">Subscribe</button>
      </form>
      <div className="icon">
        <Link to="https://www.facebook.com/GDGSousse/">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </Link>
        <Link to="https://www.linkedin.com/company/gdg-sousse">
          <i className="fa fa-linkedin" aria-hidden="true"></i>
        </Link>
        <Link to="https://www.instagram.com/gdgsousse/">
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </Link>
      </div>
      {/* <div className="fotermenu">
        <ul>
          <li>
            <Link to="#">Overview</Link>
          </li>
          <li>
            <Link to="#">Speakers</Link>
          </li>
          <li>
            <Link to="#">Event</Link>
          </li>
          <li>
            <Link to="/pricing-plan">
              Get The Ticket
            </Link>
          </li>
          <li>
            <Link to="/sponsor">Sponsorship</Link>
          </li>
        </ul>
      </div> */}
      <div className="copy">
        <span>&copy; Copyright 2024. All rights reserved by GDG Sousse</span>
      </div>
    </section>
  )
}
export default footer
