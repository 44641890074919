import React from 'react'
import Navbar from "../components/navbar/Navbar";
import Sponsor from '../components/sponsor/sponsor'
import Footer from '../components/footer/footer'
import SponsorForm from '../components/pricing/form/SponsorForm'
import defaultBG from '../assets/images/default-bg.webp'
import { navitem, image, pricingPlane, aboutbgimg, selectplan, sponsorimg, footerbg } from '../components/variables/variable'
import { Link } from 'react-router-dom';

function CallForSponsors(props) {
  return (
    <section className="prcing-page">
      <Navbar name={navitem} logo={image} action={props.event.action} />
      <nav aria-label="breadcrumb" style={{ backgroundImage: props.event?.hero.url ? `url(${process.env.REACT_APP_API_URL}${props.event.hero.url})` : `url(${defaultBG})`, backgroundSize: "cover" }}>
        <div className="overlay">
          <h1>Call for sponsors</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to='/'>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/call-for-sponsors">
                <i className="fa fa-circle" aria-hidden="true"></i>Call for sponsors
              </Link>
            </li>
          </ol>
        </div>
      </nav>
      <div className="container">
        <SponsorForm selectplan={selectplan} />
      </div>
      <Sponsor sponsors={props.event.sponsors} />
      <Footer bg={footerbg} />
    </section>
  )
}

export default CallForSponsors;